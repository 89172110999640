import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
// import "./b16-tomorrow-dark.css";
import "./markdownpage.css";

export default class MarkDownTemplate extends React.Component {
    render() {
        const {data, pageContext} = this.props;
        const {slug} = pageContext;
        const postNode = data.markdownRemark;
        const post = postNode.frontmatter;
        if (!post.id) {
            post.id = slug;
        }

        return (
          <Layout>
            <div>
              <Helmet>
                <title>{`${post.title} | ${config.siteTitle}`}</title>
              </Helmet>
              <SEO postPath={slug} postNode={postNode} postSEO />
              <div>
                <h1>{post.title}</h1>
                <div dangerouslySetInnerHTML={{__html: postNode.html}} />
              </div>
            </div>
          </Layout>
        );
    }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
    query Jawn($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html 
            timeToRead
            excerpt
            frontmatter {
                title
                cover
                date
            }
            fields {
                slug
                date
            }
        }
    }
`;
